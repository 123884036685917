// initial state
const state = () => ({
	items: [],
});

// getters
const getters = {};

// actions
const actions = {
	add ({commit}, notification) {
		commit('pushNotification', notification);
	},
	remove ({commit}, notification) {
		commit('destroyNotification', notification);
	}
};

// mutations
const mutations = {
	pushNotification(state, notification) {
		state.items.push({
			...notification,
			id: (Math.random().toString(36) + Date.now().toString(36)).substr(2)
		});
	},
	destroyNotification(state, notificationToRemove) {
		state.items = state.items.filter(notification => {
			return notification.id !== notificationToRemove.id;
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};