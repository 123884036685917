export default {
	data() {
		return {
			selEl: null,
			xpath: null
		};
	},
	methods: {
		getElementByXPath(path) {
			return (new XPathEvaluator())
				.evaluate(path, document.documentElement, null,
					XPathResult.FIRST_ORDERED_NODE_TYPE, null)
				.singleNodeValue;
		},
		getXPath() {
			const idx = (sib, name) => sib
				? idx(sib.previousElementSibling, name||sib.localName) + (sib.localName == name)
				: 1;
			const segs = elm => !elm || elm.nodeType !== 1
				? ['']
				: elm.id && document.getElementById(elm.id) === elm
					? [`id("${elm.id}")`]
					: [...segs(elm.parentNode), `${elm.localName.toLowerCase()}[${idx(elm)}]`];
			this.xpath =  segs(this.selEl).join('/');
		}
	}
}