<template>
    <div class="feedback">
        <h3>Änderungssvorschläge</h3>
        <fieldset v-show="!selectedEl">
            <legend>1. Trage deine E-Mail ein</legend>
            <input id="email" type="email" v-model.lazy="userEmail" placeholder="Deine E-Mail Adresse" name="email" autocomplete="email" required>
        </fieldset>
        <fieldset v-show="!selectedEl" :disabled="!userEmail">
            <legend>2. Art des Vorschlags wählen</legend>
            <!-- Text or Design as Buttons -->
            <div class="option-wrapper" role="listbox">
                <button type="button" role="option" :aria-selected="feedbackType === 'txt'" class="btn-fst" :class="{active: feedbackType === 'txt'}" @click="feedbackType = !feedbackType ? 'txt' : undefined">Text</button>
                <button type="button" role="option" :aria-selected="feedbackType === 'design'"  class="btn-fst" :class="{active: feedbackType === 'design'}" @click="feedbackType = !feedbackType ? 'design' : undefined">Design</button>
            </div>
        </fieldset>
        <fieldset :disabled="!feedbackType">
            <legend v-show="!selectedEl">3. Vorschlag eintragen</legend>
            <FeedbackText v-if="feedbackType === 'txt'" @selectedEl="selectedEl = $event" />
            <!--FeedbackDesign v-if="feedbackType === 'design'"/-->
        </fieldset>
    </div>
</template>

<script>
    import FeedbackText from "../components/feedback/FeedbackText.vue";
    import FeedbackDesign from "../components/feedback/FeedbackDesign.vue";

    export default {
        name: 'TabFeedback',
        data() {
            return {
                feedbackType: '',
                userEmail: localStorage.getItem('user_email'),
                selectedEl: false
            };
        },
        watch: {
            userEmail: {
                immediate: true,
                handler(value) {
                    if(value){
                        localStorage.setItem('user_email', value);
                    } else {
                        localStorage.removeItem('user_email');
                    }
                }
            },
        },
        components: {FeedbackDesign, FeedbackText}
    }
</script>