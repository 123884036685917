<template>
	<div class="feedback-prev">
		<h4>Weitere Vorschläge</h4>
		<ul v-if="feedbackList.length">
			<li v-for="item in feedbackList" :key="item.id">
				<button @click="selectPrevFeedback(item.id)" :class="{'selected' : item.id === selFeedback}">
					erstellt von {{item.email}}<br>
					<small>am  {{formateDate(item.created_at)}}</small>
				</button>
			</li>
		</ul>
		<p v-else-if="!loaded">Lädt...</p>
		<p v-else>Keine weiteren Vorschläge vorhanden!</p>
	</div>
</template>

<script>
	export default {
		name: 'PreviousFeedback',
		data() {
			return {
				feedbackList: [],
				loaded: false
			};
		},
		props: {
			type: null,
			xpath: null,
			selFeedback: null
		},
		mounted(){
			this.getPrevFeedback();
		},
		methods: {
			getPrevFeedback(){
				this.loaded = true;
				axios.get(`feedback/sites/${this.$store.getters['setup/passSiteId']}/sites/${this.$store.getters['setup/passPathId']}/${this.type}?xpath=${this.xpath}`)
					.then(response => {
						this.feedbackList = response.data;
						this.loaded = true;
					})
					.catch(error => {
						this.loaded = true;
						console.error(error);
					});
			},
			selectPrevFeedback(feedbackId){
				axios.get(`feedback/sites/${this.$store.getters['setup/passSiteId']}/texts/${feedbackId}`)
					.then(response => {
						let newTxt = response.data.new_text;
						this.$emit('selected', { feedbackId, newTxt })
					})
					.catch(error => {
						console.error(error);
					});
			},
			formateDate(date){
				return new Date(date).toLocaleDateString('de-DE');
			}
		}
	}
</script>

<style lang="scss">
	.feedback-prev{
		li{
			list-style: inside;
		}
		.selected{
			font-weight: 600;
		}
		button{
			font-size: .75rem;
		}
	}
</style>
