<template>
	<div class="notification" :class="typeClass" role="dialog">
		{{ notification.message }}
		<button type="button" class="close" @click="manualClose">&times;</button>
	</div>
</template>

<script>

	export default {
		name: 'NotificationListItem',
		props: ["notification"],
		data() {
			return {
				timeout: null
			};
		},
		created() {
			this.timeout = setTimeout(() => {
				this.$store.dispatch('notifications/remove', this.notification);
			}, 4000);
		},
		computed: {
			typeClass() {
				return this.notification.type;
			}
		},
		methods: {
			manualClose() {
				this.$store.dispatch('notifications/remove', this.notification);
			}
		},
		beforeUnmount() {
			clearTimeout(this.timeout);
		}
	};
</script>

<style lang="scss">
	.notification {
		background: rgba(#222, .9);
		padding: 8px 16px;
		border-radius: 8px;
		white-space: nowrap;
		margin: 0 0 8px;
		animation: blendIn .3s;
		border: 1px solid rgba(#fff, 0.5);
		&.success {
			background: rgba(#5f5, .9);
		}

		&.error {
			background: rgba(#f55, .9);
		}
		button{
			margin: 0;
		}
	}
</style>