const doc = document.documentElement;

export default {
	data() {
		return {
			styleHoverOverlay: null,
			scrollY: null,
			ScrollX: null
		};
	},
	methods: {
		getOverlayWrapper(){
			const shadow = document.querySelector('#mr-helper');
			const shadowRoot = shadow.shadowRoot;
			return shadowRoot.querySelector('#overlays');
		},
		defaultOverlay(e){
			const bounding = e.target.getBoundingClientRect();

			this.getScrollPos();

			this.styleHoverOverlay = {
				height: `${bounding.height}px`,
				width: `${bounding.width}px`,
				left: `${bounding.left + this.scrollX}px`,
				top: `${bounding.top + this.scrollY}px`
			};
		},
		elOverlay(e) {
			this.defaultOverlay(e);

			window.addEventListener("click", this.selectedEl, {once: true});
		},
		txtOverlay(e) {
			this.defaultOverlay(e);

			var boxShadowColor = '#f33';
			if(!document.getElementById('mr-helper').contains(e.target) && this.isTxt(e.target.tagName)){
				window.addEventListener("click", this.selectedTxt, {once: true});
				boxShadowColor = '#0b0';
			}
			else{
				// select text is inside mr-helper
				window.removeEventListener("click", this.selectedTxt);
			}

			this.styleHoverOverlay.boxShadow = `inset 0 0 1px 1px ${boxShadowColor}, inset 0 0 0 2px rgba(255,255,255,.75)`;
		},
		getScrollPos() {
			this.scrollX = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
			this.scrollY = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
		},
		isTxt(tagName){
			return tagName === "P" || tagName === "SPAN" || tagName === "H1"  || tagName === "H2" || tagName === "H3" || tagName === "H4" || tagName === "LI" || tagName === "A" || tagName === "STRONG" || tagName === "I"
		},
	}
}