<template>
    <div id="grid-overlay" v-html="gridStyle"></div>
</template>

<script>
export default {
    name: "ShowGrid",
    data() {
        return {
            gridData: undefined
        };
    },
    created() {
        this.getGridData();
    },
    computed: {
        gridStyle(){
            if(!this.gridData) return;

            let style = '<style>';
            this.gridData.forEach((item, index) => {
                const styleMediaQuery = item.from ? ` and (min-width: ${item.from}px)` : '',
                    maxWidth = item.maxWidth ? item.maxWidth + 'px' : 'initial',
                    offset = item.spaceLeft  / 2 - item.spaceRight  / 2,
                    marginLeft = `${offset}px)`,
                    width = `calc(100% - ${item.spaceLeft || 0}px - ${item.spaceRight || 0}px)`;

                let linearGradient = `
                        rgba(255, 255, 255, .3) 0,
                        rgba(255, 255, 255, .3) ${item.gap / 2}px,
                        rgba(255, 68, 255, .3) ${item.gap / 2}px,
                        rgba(255, 68, 255, .3) calc(${100 / item.columns}% - ${item.gap / 2}px),
                        rgba(255, 255, 255, .3) calc(${100 / item.columns}% - ${item.gap / 2}px),
                        rgba(255, 255, 255, .3) calc(${100 / item.columns}%)`;

                style += `
                        @media screen ${styleMediaQuery} {
                            #grid-overlay:after {
                                margin-left: ${marginLeft};
                                width: ${width};
                                max-width: ${maxWidth};
                                background-image: repeating-linear-gradient(90deg, ${linearGradient});
                                background-size: calc(100% + ${item.gap}px) 100%;
                                background-position: center;
                            }
                        }
                    `;
            });

            style += '</style>';

            return style;
        }
    },
    methods: {
        getGridData(){
            console.log("getGridData");

            const gridData = JSON.parse(localStorage.getItem('grid_data'));
            if (gridData) {
                this.gridData = gridData;
                return gridData;
            }

            //axios call to 'sites/{site}/grid'
            axios.get(`/sites/${this.$store.getters['setup/passSiteId']}/grid`)
                .then(response => {
                    const gridData = response.data;
                    localStorage.setItem('grid_data', JSON.stringify(gridData));
                    this.gridData = gridData;
                })
                .catch(error => {
                    console.log(error);
                    return false;
                });
        }
    }
}
</script>

<style lang="scss">
    #grid-overlay{
        position: fixed;
        left: calc(50% - .5px);
        top: 0;
        width: 1px;
        height: 100vh;
        background: red;
        z-index: 999;
        pointer-events: none;
        &:after {
            content: "";
            position: fixed;
            top: 0;
            left: 50%;
            transform: translate(-50%);
            height: 100%;
        }
    }
</style>