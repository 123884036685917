<template>
    <div class="seo">
        <h3>SEO</h3>
        <ul>
            <li class="option-wrapper">
                <input type="checkbox" id="show_alt_tag" v-model="showAltTag">
                <label for="show_alt_tag">Alt-Tags</label>
            </li>
            <li class="option-wrapper">
                <input type="checkbox" id="show_headline_type" v-model="showHeadlineType">
                <label for="show_headline_type">Überschrifts-Typen</label>
            </li>
        </ul>
        <template v-if="metaRobots">
            <h4>Meta-Robots</h4>
            <p>{{metaRobots}}</p>
        </template>
        <h4>Title</h4>
        <p>{{metaTitle}}</p>
        <h4>Meta-Description</h4>
        <p>{{metaDescription}}</p>
    </div>
</template>

<script>
    const metaDescription = document.querySelector('meta[name="description"]') ? document.querySelector('meta[name="description"]').content : null;
    const metaRobots = document.querySelector('meta[name="robots"]') ? document.querySelector('meta[name="robots"]').content : null;

    export default {
        name: 'TabSEO',
        data() {
            return {
                metaTitle: document.title || 'Keine Titel vorhanden',
                metaDescription: metaDescription || 'Keine Meta-Description vorhanden',
                metaRobots: metaRobots,
                showAltTag: localStorage.getItem('show_alt_tag') || false,
                showHeadlineType: localStorage.getItem('show_headline_type') || false
            };
        },
        watch: {
            showAltTag: {
				immediate: true,
				handler(value) {
                    if(value){
                        localStorage.setItem('show_alt_tag', true);
                        this.addAltTxt('alt-txt-helper');
                    } else {
                        localStorage.removeItem('show_alt_tag');
                        this.removeByClass('alt-txt-helper');
                    }
				}
			},
			showHeadlineType: {
				immediate: true,
				handler(value) {
                    if(value){
                        localStorage.setItem('show_headline_type', true);
                        this.addHeadlineType('headline-helper');
                    } else {
                        localStorage.removeItem('show_headline_type');
                        this.removeByClass('headline-helper');
                    }
				}
			},
        },
        methods: {
            addAltTxt(className) {
                var images = document.getElementsByTagName("img");
                for (let i = 0; i < images.length; i++) {
                    if(images[i].alt){
                        this.appendElement(images[i], images[i].alt, className);
                    }
                }
            },
            addHeadlineType(className) {
                this.addTagType('h1, h2, h3, h4, h5, h6', className);
            },
            addTagType(tags, className) {
                var elements = document.querySelectorAll(tags);
                for (let i = 0; i < elements.length; i++) {
                    this.appendElement(elements[i], elements[i].tagName, className);
                }
            },
            appendElement(el, content, className){
                var elem = document.createElement('div');
                elem.innerText = content;
                if(className) elem.className = className;
                elem.style.left =  `${el.offsetLeft}px`;
                elem.style.top =  `${el.offsetTop}px`;
                el.after(elem);
            },
            removeByClass(className){
                document.querySelectorAll('.' + className).forEach(e => e.remove());
            }
        }
    }
</script>
