<template>
	<div class="feedback-design">
		<p v-if="!selEl">Wähle ein Objekt für das du einen Vorschlag senden willst!</p>
		<div v-else>
			<form @submit.prevent="sendFeedback()">
				<label for="feedback-typ">Typ</label>
				<select id="feedback-typ" v-model.lazy="feedbackTyp">
					<option :value="''" selected disabled>Feedbacktyp</option>
					<option value="error">Fehler</option>
					<option value="correction">Verbesserung</option>
					<option value="idea">Idee</option>
				</select>
				<label for="feedback-txt">Vorschlag</label>
				<input id="feedback-txt" type="text" v-model.lazy="feedbackTxt" placeholder="Dein Änderungsvorschlag" required>
				<button type="submit" class="btn-fst">Feedback senden</button>
			</form>
			<h3>Weitere Vorschläge</h3>
			<ul>
				<li><button type="button">25.11.2020 - adels@necom.de</button></li>
				<li><button type="button">20.10.2020 - info@20d.rocks</button></li>
			</ul>
		</div>

		<teleport :to="getOverlayWrapper()">
			<div id="hover-overlay" v-bind:style="styleHoverOverlay"></div>
		</teleport>
	</div>
</template>

<script>
	import overlayHelper from '../../overlay-helper.js';
	import feedbackHelper from './feedback-helper.js';

	export default {
		name: 'FeedbackDesign',
		mixins: [overlayHelper, feedbackHelper],
		data() {
			return {
				feedbackTyp: '',
				feedbackTxt: ''
			};
		},
		mounted(){
			window.addEventListener("mouseover", this.elOverlay);
		},
		beforeUnmount(){
			if(this.selEl) this.endDesignFeedback();
			window.removeEventListener("mouseover", this.elOverlay);
		},
		methods: {
			selectedEl(e) {
				window.removeEventListener("mouseover", this.elOverlay);

				this.selEl = e.target;
				this.getScrollPos();

				this.styleHoverOverlay = null;
				this.selEl.style.outline = "0";
				this.selEl.style.boxShadow = "inset 0 0 2px 1px blue, inset 0 0 0 3px rgba(255,255,255,.75)";

				return false;
			},
			sendFeedback(){
				this.feedbackTyp = '';
				this.feedbackTxt = '';
				this.endDesignFeedback();
				window.addEventListener("mouseover", this.elOverlay);
			},
			endDesignFeedback(){
				this.selEl.style.boxShadow = "initial";
				this.selEl = null;
			}
		}
	}
</script>
