// initial state
const state = () => ({
	siteId: document.getElementById('mr-loader').dataset.id,
	path: window.location.pathname,
	pathId: null,
	accepted: true,
	user_email: localStorage.getItem('user_email')
});

// getters
const getters = {
	passSiteId(state) {
		return state.siteId;
	},
	passPathId(state) {
		return state.pathId;
	},
	passUserMail(state) {
		return state.user_email;
	}
};

// actions
const actions = {
	recivePathId(context){
		return new Promise((resolve, reject) => {
			axios.get(`sites/${context.state.siteId}/id?path=${context.state.path}`)
				.then(response => {
					context.commit('setPathId', response.data);
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	}
};

// mutations
const mutations = {
	setPathId(state, pathId) {
		state.pathId = pathId;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};