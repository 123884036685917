<template>
    <div id="tab-toggle">
        <ul role="tablist">
            <li v-for="tab in tabs" :key="tab.key" role="tab" :aria-controls="tab.key" :aria-selected="tab.key === activeTab">
                <button type="button" @click="toggle(tab.key)">{{ tab.name }}</button>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'ToggleTab',
        data() {
        	return {
        		activeTab: null,
        	};
        },
        props: {
            tabs: {
            	type: Array,
            	required: true
            },
        },
        created() {
            if (localStorage.getItem('active_tab') !== null) {
                this.activeTab = localStorage.getItem('active_tab');
                this.$emit('tabChange', this.activeTab);
            }
    	},
        methods: {
    		toggle(type) {
    			if(this.activeTab === type){
                    this.activeTab = null;
                    localStorage.removeItem('active_tab');
                }
                else{
                    this.activeTab = type;
                    localStorage.setItem('active_tab', this.activeTab);
                }
                this.$emit('tabChange', this.activeTab);
    		}
    	}
    }
</script>

<style lang="scss">
    #tab-toggle{
        margin: 3.75rem -1px 3.75rem 0;
        z-index: 1;
        overflow: auto;
        max-height: 100%;
        button{
            border: 1px solid #343434;
            border-radius: .25rem 0 0 .25rem;
            background: rgba(#F3F4F5, 0.85);
            padding: .5rem .25rem .5rem .5rem;
            font-size: 2rem;
            text-align: center;
            user-select: none;
            width: 3rem;
            height: 3.5rem;
            transition: background-color .2s ease-in-out;
            &:hover, &:focus-visible{
                background: #fff;
            }
        }
        li[aria-selected="true"]{
            button{
                background: #F3F4F5;
                border-right-color: #F3F4F5;
            }
        }
    }
</style>
