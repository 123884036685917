import { createStore, createLogger } from 'vuex'
import setup from './modules/setup'
import notifications from './modules/notifications'

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
	modules: {
		setup, notifications
	},
	strict: debug,
	plugins: debug ? [createLogger()] : []
})