<template>
    <div class="design">
        <h3>Design</h3>
        <ul>
            <li class="option-wrapper">
                <input type="checkbox" id="show_grid" v-model="showGrid">
                <label for="show_grid">Grid Overlay</label>
            </li>
            <li class="option-wrapper">
                <input type="checkbox" id="remove_uppercase" v-model="disallowUppercase">
                <label for="remove_uppercase">keine Versalien</label>
            </li>
            <li class="option-wrapper">
                <input type="checkbox" id="copy_txt" v-model="allowCopyTxt">
                <label for="copy_txt">Text kopieren</label>
            </li>
        </ul>
        <button type="button" @click="emulateSmartphone" class="btn-fst">Smartphone emulieren</button>

        <teleport :to="getOverlayWrapper()">
            <ShowGrid v-if="showGrid" />
            <div v-if="allowCopyTxt" id="hover-overlay" v-bind:style="styleHoverOverlay"></div>
        </teleport>
    </div>
</template>

<script>
    import ShowGrid from "@/components/ShowGrid.vue";
    import helper from '../overlay-helper.js';

    export default {
        name: 'TabDesign',
        mixins: [helper],
        data() {
            return {
                shadowRoot: null,
                showGrid: !!localStorage.getItem('show_grid') || false,
                disallowUppercase: localStorage.getItem('remove_uppercase') || false,
                allowCopyTxt: false,
                styleHoverOverlay: null
            };
        },
		watch: {
            showGrid: {
				immediate: true,
				handler(value) {
                    if(value){
                        localStorage.setItem('show_grid', true);
                    } else {
                        localStorage.removeItem('show_grid');
                    }
				}
			},
			disallowUppercase: {
				immediate: true,
				handler(value) {
                    if(value){
                        localStorage.setItem('remove_uppercase', true);
                        document.body.classList.add('remove-uppercase');
                    } else {
                        localStorage.removeItem('remove_uppercase');
                        document.body.classList.remove('remove-uppercase');
                    }
				}
			},
			allowCopyTxt: {
				handler(value) {
    				if(value){
                        window.addEventListener("mouseover", this.txtOverlay);
                      } else {
                        window.removeEventListener("mouseover", this.txtOverlay);
                    }
				}
			}
        },
        methods: {
            emulateSmartphone() {
                localStorage.removeItem('active_tab');
                window.open(window.location.href,"smartphone_emulation","menubar=1,resizable=1,width=375,height=812");
            },
            selectedTxt(e) {
                const selection = window.getSelection();
                const range = document.createRange();
                range.selectNodeContents(e.target);
                selection.removeAllRanges();
                selection.addRange(range);

                document.execCommand('copy');
                selection.removeAllRanges();

                this.$store.dispatch('notifications/add', {
                    type: 'success',
                    message: 'Erfolgreich kopiert'
                }, {root: true});

                e.preventDefault();
            },
        },
        components: {ShowGrid}
    }
</script>

<style lang="scss">
    #hover-overlay{
        position: absolute;
        z-index: 9;
        pointer-events: none;
        background: rgba(#05f, .25);
    }
</style>
