//create shadow dom
const elem = document.createElement('div');
elem.id = 'mr-helper';
document.body.appendChild(elem);

const node = elem.attachShadow({mode: 'open'});

//add app wrapper, overlay wrapper and mr helper css to shadow dom
node.innerHTML = '<div id="app"></div><div id="overlays"></div>';

if (process.env.NODE_ENV === 'development'){
	//move inserted css to shadow dom
	const allInternalCSS = document.querySelectorAll('style');
	allInternalCSS.forEach((item) => {
		var style = document.createElement('style');
		style.type = 'text/css';
		style.innerText = item.innerHTML;
		node.appendChild(style);
	});
}
else{
	//add link to external css to shadow dom
	const link = document.createElement('link');
	link.href = 'https://my-relaunch.com/helper/app.css?id=' + process.env.VUE_APP_VERSION;
	link.type = 'text/css';
	link.rel = 'stylesheet';
	node.appendChild(link);
}


//apply vue to app element in the shadow dom
const shadowChild = node.getElementById("app");
import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
window.axios = require('axios');
window.axios.defaults.baseURL = 'https://my-relaunch.com/api';

createApp(App).use(store).mount(shadowChild);



//add CSS to Site
const css = '.remove-uppercase *{text-transform:inherit!important}.alt-txt-helper,.headline-helper{position:absolute;z-index:9;background:red;padding:2px5px;color:#fff;opacity:.9;pointer-events:none;}',
	head = document.head || document.getElementsByTagName('head')[0],
	style = document.createElement('style');
	style.innerHTML = css;
head.appendChild(style);