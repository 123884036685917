<template>
	<div class="notification-list">
		<NotificationListItem
			v-for="notification in items"
			:key="notification.id"
			:notification="notification"
		/>
	</div>
</template>

<script>
	import NotificationListItem from "./NotificationListItem";
	import {mapState} from "vuex";

	export default {
		name: 'NotificationList',
		components: {
			NotificationListItem
		},

		computed: mapState('notifications', ['items'])
	};
</script>


<style>
	.notification-list {
		position: fixed;
		z-index: 9999;
		right: 1.5rem;
		top: 1.5rem;
	}
</style>