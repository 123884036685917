<template>
    <div class="links">
        <h3>Links</h3>
        <ul>
            <li v-for="link in links" :key="link.url">
                <a :href="`https://${link.url}${currentUrl}`" target="_blank" rel="noopener noreferrer">{{ link.name }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
    const protocol = window.location.protocol.replace(/\:/, "");

    export default {
        name: 'TabLink',
        data() {
          return {
              currentUrl: encodeURI(window.location.host + window.location.pathname),
              links: [
                  {url: `search.google.com/test/rich-results?url=${protocol}://`, name: 'Google Strukturierte Daten'},
                  {url: `search.google.com/test/mobile-friendly?url=${protocol}://`, name: 'Google Mobil Optimierung'},
                  {url: `developers.google.com/speed/pagespeed/insights/?hl=de&url=${protocol}://`, name: 'Google PageSpeed Insights'},
                  {url: `validator.w3.org/nu/?doc=${protocol}://`, name: 'W3C Markup Validation Service'},
                  {url: `realfavicongenerator.net/favicon_checker?protocol=${protocol}&site=`, name: 'RealFaviconCheck'},
              ]
          };
        },
    }
</script>
