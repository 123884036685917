<template>
    <div class="inner" :class="{active : activeHelper}">
        <TabToggle :tabs="[{key: 'design', name: '📐'}, {key: 'feedback', name: '💡'}, {key: 'seo', name: '🤖'}, {key: 'links', name: '📎'}]"  @tabChange="(newTab) => {activeHelper = newTab}" />
        <div id="helper-wrapper">
            <TabDesign v-show="activeHelper === 'design'" class="content" role="tabpanel" aria-labelledby="design" />
            <TabFeedback v-show="activeHelper === 'feedback'" class="content" role="tabpanel" aria-labelledby="feedback" />
            <TabSEO v-show="activeHelper === 'seo'" class="content" role="tabpanel" aria-labelledby="seo" />
            <TabLink v-show="activeHelper === 'links'" class="content" role="tabpanel" aria-labelledby="links" />
            <p class="copyright" v-show="activeHelper === 'links'">
                powerd by <a href="https://my-relaunch.com" target="_blank" rel="noopener noreferrer" style="text-decoration:underline">my-relaunch.com</a>
            </p>
        </div>
    </div>
    <NotificationList />
</template>

<script>
import TabToggle from './views/ToggleTab.vue';
import TabDesign from './views/TabDesign.vue';
import TabFeedback from './views/TabFeedback.vue';
import TabSEO from './views/TabSEO.vue';
import TabLink from './views/TabLink.vue';
import NotificationList from './components/messages/NotificationList.vue';

export default {
    name: 'App',
    mounted() {
        this.$store.dispatch('setup/recivePathId')
            .catch(error => {
              this.$store.dispatch('notifications/add', {
                type: 'error',
                message: error,
              }, {root: true});
            });
    },
    data() {
    	return {
    		activeHelper: null,
    	};
    },
    components: {
        TabToggle, TabDesign, TabFeedback, TabSEO, TabLink, NotificationList
    }
}
</script>

<style lang="scss">
    $main-width: 15.5rem;

    :host {
        all: initial;
        contain: content;
        font-family: Avenir, Helvetica, Arial, sans-serif;
    }
    *, *:before, *:after{
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
    :disabled{
        opacity: .5;
        pointer-events: none;
    }
    .inner{
        pointer-events: none;
        position: fixed;
        display: flex;
        align-items: center;
        right: 0;
        top: 50%;
        height: 28rem;
        max-height: calc(100vh - 3.75rem);
        translate: $main-width -50%;
        transition: transform .3s ease-in-out;
        z-index: 9999;
        &.active{
            transform: translateX(-$main-width);
        }
    }
    #helper-wrapper, #tab-toggle{
        pointer-events: initial;
    }
    #helper-wrapper{
        background: #F3F4F5;
        border: 1px solid #343434;
        border-right: 0;
        border-radius: .25rem 0 0 .25rem;
        display: block;
        height: 100%;
        width: $main-width;
        overflow: auto;
        padding: 1rem;
        input, button, a{
            &:hover, &:focus-visible{
                outline: 1px solid #9c9c9c;
            }
        }
    }
    div, p, label, button{
        font-size: 1rem;
        color:  #343434;
        padding: 0;
        line-height: 1.2;
    }
    div, label{
        margin: 0;
    }
    label{
        display: inline-block;
    }
    p, button{
        margin: 0 0 1rem;

    }
    h3, h4{
        font-weight: 600;
    }
    h3{
        font-size: 1.25rem;
        margin: 0 0 1.25rem;
    }
    h4 {
        font-size: 1rem;
        margin: .25rem 0;
    }
    fieldset{
        border: 0;
        legend{
            @extend h4;
        }
    }
    button{
        background: 0;
        border: 0;
        cursor: pointer;
        text-align: left;
    }
    ul{
        padding: 0;
        margin: 0 0 1rem;
    }
    li{
        list-style: none;
        margin: 0 0 .5rem;
        padding: 0;
        button{
            margin: 0;
        }
    }
    a{
        color: #343434;
        border-radius: .25rem;
    }
    small{
        font-size: .75em;
    }
    input{
        -webkit-appearance: initial;
        padding: .75rem;
        margin: 0 0 1rem;
        border-radius: .25rem;
        &[type="email"], &[type="text"] {
            font-size: 1rem;
            border: none;
            -webkit-appearance: initial;
        }
        &[type=checkbox], &[type=radio] {
            width: 1.5rem;
            aspect-ratio: 1;
            position: relative;
            margin: 0 1rem 0 0;
            cursor: pointer;
            outline: 1px solid #343434;
        }
        &[type=checkbox] {
            &:checked {
                background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg viewBox='0 0 12 8.949' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.076 8.773l-3.9-3.9a.6.6 0 0 1 0-.849l.849-.849a.6.6 0 0 1 .849 0L4.5 5.802 10.127.175a.6.6 0 0 1 .849 0l.849.849a.6.6 0 0 1 0 .849l-6.9 6.9a.6.6 0 0 1-.849 0z'/%3E%3C/svg%3E%0A") center no-repeat;
                background-size: 1lh;
            }
        }
    }
    .content{
        display: inline-block;
        min-height: calc(100% - 1.5rem);
    }
    .option-wrapper{
        display: flex;
        align-items: center;
        gap: .5rem;
        input{
            margin: 0;
        }
        label{
            margin: 0 .25rem 0;
        }
    }
    .btn-fst {
        background: #fff;
        border-radius: .25rem;
        margin: 0 0 1rem;
        color: #343434;
        padding: .5rem .75rem;
        &[aria-selected="true"]{
            background: #E64D4D;
            color: #fff;
        }
    }
    .copyright{
        position: relative;
        font-size: .75rem;
        top: .75rem;
        margin: 0
    }
</style>
