<template>
	<div class="feedback-txt">
		<p v-if="!selEl">Wähle einen Text für den du einen Vorschlag senden willst!</p>
		<template v-else>
			<label for="feedback-note">Notiz</label>
			<input id="feedback-note" type="text" v-model.lazy="note" placeholder="Notiz zur Änderung">
            <div class="option-wrapper">
                <button type="button" @click="sendFeedback" class="btn-fst">Absenden</button>
                <button type="button" @click="cancelTxtChange">Abbrechen</button>
            </div>
			<h4>Alter Text</h4>
			<div class="old-txt" v-html="sansanitizedOldTxt"></div>
			<PreviousFeedback :type="this.type" :xpath="this.xpath" :selFeedback="this.selectedFeedback"  @selected="showSel" />
		</template>

		<teleport :to="getOverlayWrapper()">
			<div id="hover-overlay" v-bind:style="styleHoverOverlay"></div>
			<div id="txtedit-menue"  v-if="styleTxtMenue" v-bind:style="styleTxtMenue">
				<button @mousedown="showOldTxt" @mouseup="hideOldTxt" @touchstart="showOldTxt" @touchend="hideOldTxt" @touchcancel="hideOldTxt" title="Alten Text anzeigen">👁</button>
				<button @click.prevent="cancelTxtChange" title="Änderung abbrechen">❌</button>
				<button @click.prevent="saveTxtChange" title="Änderung absenden">📤</button>
			</div>
		</teleport>
	</div>
</template>

<script>
	import DOMPurify from 'dompurify';
	import overlayHelper from '../../overlay-helper.js';
	import feedbackHelper from './feedback-helper.js';
	import PreviousFeedback from './PreviousFeedback.vue';

	export default {
		name: 'FeedbackText',
		mixins: [overlayHelper, feedbackHelper],
		data() {
			return {
				type: 'texts',
				styleTxtMenue: null,
				oldTxts: [],
				newTxt: '',
				note: null,
				selectedFeedback: null,
			};
		},
		mounted(){
			window.addEventListener("mouseover", this.txtOverlay);
			this.loadSiteFeedback();
		},
		beforeUnmount(){
			if(this.selEl) this.endTxtChange();
			this.showAllOldTxts();
			window.removeEventListener("click", this.txtOverlay);
		},
		methods: {
			selectedTxt(e) {
				this.selEl = e.target;

				this.getXPath();

				window.removeEventListener("mouseover", this.txtOverlay);

				this.getScrollPos();

				const bounding = this.selEl.getBoundingClientRect();

				this.styleTxtMenue = {
					left: `${bounding.left + scrollX + bounding.width}px`,
					top: `${bounding.top + scrollY + bounding.height}px`
				};
				this.styleHoverOverlay = null;
				this.selEl.style.outline = "0";
				this.selEl.style.boxShadow = "inset 0 0 2px 1px blue, inset 0 0 0 3px rgba(255,255,255,.75)";

				this.addOldTxt(this.xpath, this.selEl.innerHTML);

				this.selEl.contentEditable = "true";

                this.$emit('selectedEl', this.selEl);

				return false;
			},
			addOldTxt(xpath, txt) {
				const obj = {xpath: xpath, txt: txt};
				if(!this.oldTxts.includes(obj))this.oldTxts.push(obj);
			},
			hideOldTxt(){
				if(this.newTxt) this.selEl.innerHTML = this.sansanitizedNewTxt;
			},
			showOldTxt(){
				this.newTxt = this.selEl.innerHTML;
				this.selEl.innerHTML = this.sansanitizedOldTxt;
			},
			showAllOldTxts() {
				this.oldTxts.forEach((item) => {
					const el = this.getElementByXPath(item.xpath);
					if(el) el.innerHTML = DOMPurify.sanitize(item.txt)
				})
			},
			cancelTxtChange(){
				this.selEl.innerHTML = this.sansanitizedOldTxt;
				this.endTxtChange();
				window.addEventListener("mouseover", this.txtOverlay);
                this.$emit('selectedEl', null);
			},
			saveTxtChange(){
				this.newTxt = this.selEl.innerHTML;
				this.sendFeedback();
				this.endTxtChange();
				window.addEventListener("mouseover", this.txtOverlay);
			},
			endTxtChange(){
				this.newTxt = null;
				this.styleTxtMenue = null;
				this.selEl.contentEditable = "false";
				this.selEl.style.boxShadow = "initial";
				this.selEl = null;
			},
			sendFeedback() {
				let values = {
					xpath: this.xpath,
					email: this.$store.getters['setup/passUserMail'],
					note: this.note,
					old_text: this.sansanitizedOldTxt,
					new_text: this.sansanitizedNewTxt
				};

				axios.post(`feedback/sites/${this.$store.getters['setup/passSiteId']}/pages/${this.$store.getters['setup/passPathId']}/texts`, values)
					.then(response => {
						this.$store.dispatch('notifications/add', {
							type: 'success',
							message: 'Erfolgreich eingereicht!'
						}, {root: true});
					})
					.catch(error => {
						this.$store.dispatch('notifications/add', {
							type: 'error',
							message: error,
						}, {root: true});
					});
			},
			loadSiteFeedback() {
				axios.get(`feedback/sites/${this.$store.getters['setup/passSiteId']}/pages/${this.$store.getters['setup/passPathId']}/${this.type}?email=${this.$store.getters['setup/passUserMail']}`)
					.then(response => {
						let res = response.data;

						res.forEach((item) => {
							const el = this.getElementByXPath(item.xpath);
							if(el){
								this.addOldTxt(item.xpath, el.innerHTML);
								el.innerHTML = DOMPurify.sanitize(item.new_text);
							}
							else console.warn(item.xpath + ' has removed from dom')
						});
					})
					.catch(error => {
						console.error(error);
					});
			},
			showSel({feedbackId, newTxt}){
				this.selectedFeedback = feedbackId;
				this.selEl.innerHTML = DOMPurify.sanitize(newTxt);
			}
		},
		computed: {
			sansanitizedOldTxt(){
				if(this.xpath) return DOMPurify.sanitize(this.oldTxts.find(x => x.xpath === this.xpath).txt);
			},
			sansanitizedNewTxt(){
				return DOMPurify.sanitize(this.newTxt);
			}
		},
		components: {
			PreviousFeedback
		}
	}
</script>

<style lang="scss">
	.old-txt{
		max-height: 5.5em;
        margin: 0 0 1rem;
		overflow: auto;
	}
	#txtedit-menue{
		position: absolute;
        display: flex;
        gap: .5rem;
		transform: translateX(-100%);
		button{
			background: #F3F4F5;
			border: 1px solid #343434;
            border-radius: .25rem;
			padding: .5rem;
            transition: background-color .6s ease;
            &:hover, &:focus-visible{
                background: #343434;
            }
		}
	}
</style>
